import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const Header = () => {
  return(
    <section className="mt-5 lg:mt-20">
      <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
        <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">How it works</h1>
        <p className="text-base font-semibold lg:text-2xl">Relay focuses on providing a superb customer experience and efficient use of infrastructure.</p>
      </div>
    </section>
  )
}

const Faq = () => {
  return(
    <div>
      
    </div>
  )
}

const MajorFeatures = () => {
  return(
    <div className="mt-5 lg:mx-auto lg:max-w-screen-md">
      <div className="col-span-2 p-8 mb-8 lg:rounded-xl bg-relay-green-background">
        <h2 className="mb-6 text-3xl font-bold text-relay-green-secondary">No app required</h2>
        <p className="mb-4">
          Relay does not require users to install any apps. It uses Internet technologies coupled 
          with security technologies like <a href="https://en.wikipedia.org/wiki/One-time_password" className="underline text-relay-green-secondary">one-time-passwords</a> 
          to achieve a frictionless experience.
        </p>
        <p className="mb-4">
          All notifications are sent via <a href="https://en.wikipedia.org/wiki/SMS" className="underline text-relay-green-secondary">SMS</a> which is available on
          any mobile device across all international mobile networks.
        </p>
        <p>
          All interfaces are mobile first and the visitor's experience is completely temporary which fades away as they leave the destination.
        </p>
      </div>
      <div className="col-span-2 p-8 mb-8 lg:rounded-xl bg-relay-green-background">
        <h2 className="mb-6 text-3xl font-bold text-relay-green-secondary">At the destination</h2>
        <p className="mb-4">
          We provide a multi-user web based experience to manage your charger wait lists. Relay provides well researched data for
          charging equipment to ease setup.
        </p>
        <p className="mb-4">
          We provide tools for bad actor management without visitors having to get involved in conflict or management of
          the charging equipment.
        </p>
      </div>

    </div>
  )
}

const HowItWorksPage = () => {
  return (
    <Layout>
      <Seo 
        title="How it works" 
        description="Under the hood of the platform"
      />
      <Header/>
      <MajorFeatures/>
    </Layout>
  )
}

export default HowItWorksPage
